<template>
<div><Header />
    <div class="my-5 pt-sm-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="text-center">
                            <div class="mb-5">
                                <a href="index.html" class="">
                                    <img src="assets/images/logo-dark.png" alt="" height="20" class="auth-logo logo-dark mx-auto">
                                    <img src="assets/images/logo-light.png" alt="" height="20" class="auth-logo logo-light mx-auto">
                                </a>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-sm-4">
                                    <div class="maintenance-img">
                                        <img src="../assets/images/maintenance-bg.png" alt="maintenance-bg" class="img-fluid mx-auto d-block">
                                    </div>
                                </div>
                            </div>
                            <h3 class="mt-5">Site is Under Maintenance</h3>
                            <p>Please check back in sometime.</p>

                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <Footer />
        </div>
</template>
<script>

import Header from '../components/Header.vue';

import Footer from '../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  
};
</script>